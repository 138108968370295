@use 'styles' as theme;

.button {
  height: theme.size(5);
}

.icon {
  .button:hover & {
    color: theme.$colorWhite;
  }
}

.playStoreIcon {
  width: theme.size(2.5);
}

.appStoreIcon {
  color: theme.$colorBlack;
  width: theme.size(2.5);
  transform: translateY(-2px);
}
