// NOTE: All media queries must start with `media` prefix for stylelint to differentiate from other mixins
// See stylelint.config.js "order/order"
@use '../variables/index' as var;

@function _getQuery($min: null, $max: null, $orientation: null) {
  $minValue: if($min != null, ' and (min-width: #{$min})', '');
  $maxValue: if($max != null, ' and (max-width: #{$max})', '');
  $orientationValue: if($orientation != null, ' and (orientation: #{$orientation})', '');

  @return 'print, screen#{$minValue}#{$maxValue}#{$orientationValue}';
}

@mixin media($min: null, $max: null, $orientation: null) {
  @media #{_getQuery($min: $min, $max: $max, $orientation: $orientation)} {
    @content;
  }
}

@mixin mediaTabletPortraitUp {
  @include media($min: var.$breakpointTabletPortrait) {
    @content;
  }
}

@mixin mediaTabletPortraitDown {
  @include media($max: var.$breakpointTabletPortrait - 1) {
    @content;
  }
}

@mixin mediaTabletLandscapeUp {
  @include media($min: var.$breakpointTabletLandscape) {
    @content;
  }
}

@mixin mediaTabletLandscapeDown {
  @include media($max: var.$breakpointTabletLandscape - 1) {
    @content;
  }
}

@mixin mediaDesktopUp {
  @include media($min: var.$breakpointDesktop) {
    @content;
  }
}

@mixin mediaDesktopDown {
  @include media($max: var.$breakpointDesktop - 1) {
    @content;
  }
}

@mixin mediaDesktopBigUp {
  @include media($min: var.$breakpointDesktopBig) {
    @content;
  }
}

@mixin mediaDesktopBigDown {
  @include media($max: var.$breakpointDesktopBig - 1) {
    @content;
  }
}

@mixin mediaPhoneOnly($orientation: null) {
  @include media($max: var.$breakpointTabletPortrait - 1, $orientation: $orientation) {
    @content;
  }
}

@mixin mediaPhoneAndTabletPortraitOnly($orientation: null) {
  @include media($max: var.$breakpointTabletLandscape - 1, $orientation: $orientation) {
    @content;
  }
}

@mixin mediaPhoneAndTabletOnly {
  @include media($max: var.$breakpointDesktop - 1) {
    @content;
  }
}

@mixin mediaTabletPortraitOnly($orientation: null) {
  @include media(
    $min: var.$breakpointTabletPortrait,
    $max: var.$breakpointTabletLandscape - 1,
    $orientation: $orientation
  ) {
    @content;
  }
}

@mixin mediaTabletLandscapeOnly($orientation: null) {
  @include media(
    $min: var.$breakpointTabletLandscape,
    $max: var.$breakpointDesktop - 1,
    $orientation: $orientation
  ) {
    @content;
  }
}

@mixin mediaTabletOnly($orientation: null) {
  @include media(
    $min: var.$breakpointTabletPortrait,
    $max: var.$breakpointDesktop - 1,
    $orientation: $orientation
  ) {
    @content;
  }
}

@mixin mediaCustom($min: null, $max: null, $orientation: null) {
  @include media($min: $min, $max: $max, $orientation: $orientation) {
    @content;
  }
}

@mixin hideUntilTablet {
  @include mediaPhoneOnly {
    display: none;
  }
}

@mixin hideUntilTabletLandscape {
  @include mediaPhoneAndTabletPortraitOnly {
    display: none;
  }
}

@mixin hideUntilDesktop {
  @include mediaPhoneAndTabletOnly {
    display: none;
  }
}

@mixin hideFromTablet {
  @include mediaTabletPortraitUp {
    display: none;
  }
}

@mixin hideFromTabletLandscape {
  @include mediaTabletLandscapeUp {
    display: none;
  }
}

@mixin hideFromDesktop {
  @include mediaDesktopUp {
    display: none;
  }
}

@mixin hideOnPrint {
  @media print {
    display: none;
  }
}

@mixin printOnly($display: block) {
  display: none;

  @media print {
    display: $display;
  }
}
