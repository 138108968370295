@use 'styles' as theme;

.root {
  @include theme.flexCenter($direction: column);

  min-height: 100%;
  text-align: center;
}

.content {
  padding: theme.size(2 0 3);

  > p {
    margin-top: 1em;

    &:last-child {
      font-weight: theme.$weightSemiBold;
    }
  }
}

.icon {
  @include theme.square(theme.size(9));

  margin-bottom: theme.size(2);
}
