@use '../variables' as var;

@mixin unstyledText {
  font-family: var.$fontFamilyPrimary;
  font-size: var.$fontSizeDefault;
  font-weight: var.$weightRegular;
}

@mixin unstyledLink {
  @include unstyledText;

  color: var.$colorGrey900;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

@mixin unstyledButton {
  @include unstyledText;

  padding: 0;
  border: 0;
  border-radius: 0;
  margin: 0;
  appearance: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  line-height: inherit;
  text-align: left;

  &[disabled] {
    cursor: not-allowed;
  }
}

@mixin unstyledInput {
  @include unstyledText;

  padding: 0;
  border: 0;
  border-radius: 0;
  margin: 0;
  appearance: none;
  background: transparent;
  box-shadow: none;
  line-height: normal;
  text-align: left;
}

@mixin unstyledList {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin unstyledDL {
  margin: 0;

  dd {
    margin: 0;
  }
}

@mixin unstyledDialog {
  position: static;
  display: block;
  border: 0;
  margin: 0;
}

@mixin hideScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
}
