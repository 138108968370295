@use 'styles' as theme;

.root {
  @include theme.hideOnPrint;

  overflow: hidden;
  position: relative;
  z-index: 1;
  background: theme.$colorBlack;
  padding: theme.size(6 0 3);


  @include theme.mediaPhoneOnly {
    padding-bottom: theme.size(6);
  }
}
