@use 'styles' as theme;

.root {
  width: 100%;
  max-width: theme.size(63.5);
}

.fullWidth {
  max-width: 100%;
}

.inputWrapper {
  width: 100%;
}

.toggleWrapper {
  display: flex;
  margin: 0 auto;

  @include theme.mediaTabletPortraitUp {
    margin: 0;
  }
}

.ctaWrapper {
  display: flex;
  width: 100%;
}
