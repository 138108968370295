@use 'sass:map';
@use '../variables' as var;
@use '../utils' as util;

@mixin innerBorder($width, $color) {
  @if $color and $width {
    box-shadow: inset 0 0 0 $width $color;
  }
}

@mixin boxShadow($level: 1, $color: 'black') {
  box-shadow: map.get(map.get(var.$shadows, $color), $level);
}

@mixin bottomFade($color: var.$colorWhite, $height: util.size(3), $offset: 0) {
  position: relative;

  &::after {
    position: absolute;
    right: $offset;
    bottom: $offset;
    left: $offset;
    height: $height;
    background: linear-gradient(to top, rgba($color, 1) 0%, rgba($color, 0) 100%);
    content: '';
    pointer-events: none;
  }
}
