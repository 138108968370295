@use 'styles' as theme;
@import '../../../styles/variables.module';

.root {
  @include theme.boxShadow;
  border-radius: theme.$borderRadiusXS;
  background: theme.$colorWhite;
  border: 1px solid theme.$colorBlue;
  color: theme.$colorBlack;
  padding: theme.size(4);
  max-width: theme.size(45);
  z-index: $zIndexTooltip;
}
