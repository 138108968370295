@use 'styles' as theme;

.root {
  @include theme.unstyledButton;
  @include theme.textMStrong;
  @include theme.outlineOnFocus($selector: '&[data-focus-visible], &:focus-visible');
  @include theme.transition(background-color, $focusOutline: true);
  @include theme.flex($direction: row, $crossAxisAlign: center, $mainAxisAlign: space-between);

  -webkit-tap-highlight-color: transparent;
  position: relative;
  border-radius: theme.$borderRadiusXXS;
  width: 100%;
  flex: 1;
  padding: theme.size(1.75 1);
  box-sizing: content-box;
  margin: theme.size(0 -1);
  gap: theme.size(1);
  color: theme.$colorBlack;

  &[data-hovered],
  &[data-pressed] {
    background-color: theme.$colorGrey50;
  }

  &:hover,
  &:active {
    background-color: theme.$colorGrey50;
  }
}

.active {
  @include theme.textMStrong;
  color: theme.$colorBlue;
}
