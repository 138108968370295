@use 'styles' as theme;
@import '../../../styles/mixins';

$pageTopOffset: 124px;

.root {
  @include theme.boxShadow;
  @include theme.square(auto);
  @include positionFill(fixed, theme.size(2));

  margin: 0;
  padding: 0;
  background-color: theme.$colorWhite;
  border-radius: theme.$borderRadiusXS;
  border: 1px solid theme.$colorBlue500;
  overflow: hidden;
  z-index: $zIndexSupportButton;
  display: flex;
  flex-direction: column;
  text-align: left;
  transform-origin: bottom right;

  @include for-tablet-portrait-up {
    top: auto;
    width: 100%;
    max-height: theme.size(67.5);
    max-width: theme.size(44);
    height: calc(100% - $pageTopOffset);
    margin: auto 0 auto auto;
  }
}

.main {
  display: flex;
  flex: auto;
  flex-direction: column;
  width: 100%;
}

.contentWrapper {
  overflow: hidden auto;
}

.content {
  padding: theme.size(2);
}

.header {
  background-color: theme.$colorBlue;
  display: flex;
  align-items: center;
}

.close {
  @include theme.unstyledButton;
  @include theme.flexCenter;
  @include theme.circle(theme.size(3));

  background-color: theme.$colorWhite;

  &:hover {
    transform: scale(1.1);
  }
}

.title {
  flex: 1;
}
