@use 'sass:math';
@use '../utils' as util;

// Font families
$fontFamilyPrimary: 'Inter', system-ui, sans-serif;

// Font weights
$weightRegular: 400;
$weightSemiBold: 600;
$weightBold: 700;

// Defaults
$fontSizeDefault: util.rem(16);
$lineHeightDefault: 1.5;
