@use 'styles' as theme;
@import '../../../styles/mixins';

.root {
  @include theme.unstyledButton;
  @include theme.outlineOnFocus;
  @include theme.transition(transform);
  @include theme.flexCenter;

  display: flex;
  background-color: theme.$colorBlue;
  box-shadow: 0 0 0 1px theme.$colorWhite;
  border-radius: theme.size(12.5);
  color: theme.$colorWhite;
  height: theme.size(6);
  white-space: nowrap;
  overflow: hidden;

  @include print {
    display: none;
  }
}

.text {
  padding-left: theme.size(2);
}
