@use 'styles' as theme;

.tooltipButton {
  @include theme.outlineOnFocus;
  @include theme.unstyledButton;
  @include theme.flexCenter;
  @include theme.circle(#{theme.size(3)});
  @include theme.transition(color);

  color: theme.$colorGrey400;

  &:hover {
    color: theme.$colorBlack;
  }
}

.tooltip {
  @include theme.boxShadow;

  background-color: theme.$colorWhite;
  border-radius: theme.$borderRadiusL;
  padding: theme.size(3);
  max-width: theme.size(50);

  svg {
    display: none;
  }
}
