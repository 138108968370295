@use 'styles' as theme;

.root {
  margin-bottom: theme.size(4);

  @include theme.mediaTabletPortraitUp {
    margin-bottom: theme.size(6);
  }
}

.block {
  width: 100%;
  max-width: theme.size(54);
}

.form {
  width: 100%;
}

.privacyLink {
  @include theme.outlineOnFocus;

  color: theme.$colorGrey600;
  font-weight: theme.$weightBold;
}
