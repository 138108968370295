@use 'styles' as theme;

.dialog {
  @include theme.hideFromDesktop;

  position: fixed;
  inset: var(--pageOffset) 0 0 auto;
  background-color: theme.$colorWhite;
  max-width: 100%;
  width: 100%;
  max-height: calc(100dvh - var(--pageOffset));
  height: fit-content;
  z-index: -1;
  overflow: hidden;
}

.backdrop {
  @include theme.hideFromDesktop;

  position: fixed;
  inset: 0;
  background-color: rgba(theme.$colorBlack, 0.5);
  z-index: -1;
}

.loginActions {
  margin: theme.size(2) 0;
  display: flex;
  align-items: stretch;
}

.marketSwitcherWrapper {
  display: flex;
  flex-direction: column;
}

.content {
  position: absolute;
  inset: 0;
  height: min-content;
}

.disabledInteraction {
  pointer-events: none;
}

.items {
  --xPadding: #{theme.$framePaddingMobileUp};

  width: 100%;
  padding: theme.size(1) var(--xPadding) theme.size(2);
  overflow: hidden auto;
  max-height: calc(100dvh - var(--pageOffset));

  @include theme.mediaTabletLandscapeUp {
    --xPadding: #{theme.$framePaddingTabletLandscapeUp};
  }
}
