@use 'styles' as theme;

.root {
  display: grid;
  gap: theme.size(2);
  grid-template-areas:
    'leftCol'
    'rightCol'
    'footer';
}

.twoColumns {
  &.root {
    @include theme.mediaTabletPortraitUp {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr auto;
      grid-template-areas:
        'leftCol rightCol'
        'footer footer';
      gap: theme.size(2 4);
    }
  }
}

.messageField {
  resize: none;
}

.leftColumn {
  grid-area: leftCol;
}

.rightColumn {
  grid-area: rightCol;
}

.submitButton {
  .twoColumns & {
    padding-left: theme.size(14);
    padding-right: theme.size(14);

    @include theme.mediaPhoneOnly {
      width: 100%;
    }
  }
}

.footer {
  grid-area: footer;

  .twoColumns & {
    display: flex;
    gap: theme.size(4);
    flex-direction: column;
    align-items: flex-start;
  }
}

.policyCheckbox {
  padding-top: theme.size(4);
}

.policyLink {
  color: theme.$colorBlue;

  &:hover {
    text-decoration: underline;
  }
}
