@use 'styles' as theme;

@mixin _input {
  @include theme.transition(box-shadow);

  padding: theme.size(0.5);
  border-radius: theme.$borderRadiusS;
  background-color: var(--bgColor);
  box-shadow: 0 0 0 var(--borderWidth) var(--outlineColor);
  border: 1px solid var(--borderColor);
}

.root {
  --textColor: #{theme.$colorBlack};
  --outlineColor: transparent;
  --borderColor: transparent;
  --borderWidth: 2px;
  --bgColor: #{theme.$colorWhite};
}

.focused {
  --outlineColor: #{theme.$colorBlack};
}

.error {
  --outlineColor: #{theme.$colorRed};
  --textColor: #{theme.$colorRed};
}

.disabled {
  --bgColor: #{theme.$colorGrey50};
}

.light {
  --borderColor: #{theme.$colorBlack};
  --borderWidth: 1px;
}

.m {
  @include _input;
}

.l {
  @include theme.mediaTabletPortraitUp {
    @include _input;
  }
}

.prefix {
  .m & {
    height: theme.size(7);
  }

  .l & {
    height: theme.size(8);
  }
}

.buttonWrapper {
  .m & {
    padding: theme.size(0.5 0.5 0.5 0);
  }

  .l & {
    @include theme.mediaTabletPortraitUp {
      padding: theme.size(1 1 1 0);
    }
  }
}

.container {
  overflow: hidden;

  .l & {
    @include theme.mediaPhoneOnly {
      @include _input;

      padding-right: theme.size(1.5);
    }
  }
}

.stretchedItem {
  margin: auto 0;
  flex: 1;
}

.roundedWrapper {
  border-top-left-radius: theme.$borderRadiusXS;
  border-bottom-left-radius: theme.$borderRadiusXS;
  overflow: hidden;
  transform: translateZ(0); // Issues on safari - overflow doesn't work with border-radius
}

.labelWrapper {
  flex: 1;
  position: relative;

  .m & {
    padding: theme.size(1.5 0 1.5 3);
  }

  .l & {
    padding: theme.size(2 0 2 3);
  }
}

.label {
  @include theme.visuallyHidden;
}

.input {
  @include theme.unstyledInput;
  @include theme.textM;

  color: var(--textColor);
  text-transform: uppercase;
  width: 100%;
  position: relative;
  z-index: 1;

  &:focus {
    outline: none;
  }

  // Override default autofill styles set by the browser (User Agent)
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--textColor);
    -webkit-box-shadow: 0 0 0 theme.size(2) theme.$colorWhite inset;
  }

  @include theme.mediaTabletPortraitUp {
    @include theme.textL;
  }
}

.submitButton {
  width: 100%;
  padding-left: theme.size(3);
  padding-right: theme.size(3);

  @include theme.mediaTabletPortraitUp {
    width: auto;
  }
}

.roundButton {
  @include theme.circle(theme.size(6));

  padding: 0;
}

.explainerButton {
  @include theme.unstyledButton;
  @include theme.flexCenter($inline: true);
  @include theme.outlineOnFocus($width: 1px);
  @include theme.transition(color transform, $focusOutline: true);
  @include theme.extendedClickableArea(theme.size(1.5));

  color: theme.$colorGrey400;
  vertical-align: middle;
  border-radius: 50%;
  margin: theme.size(0 1.5);

  &:hover {
    color: theme.$colorGrey600;
  }

  .l & {
    @include theme.mediaPhoneOnly {
      margin-right: theme.size(1.5);
    }
  }
}

.desktopError {
  @include theme.hideUntilTablet;
}

.mobileError {
  @include theme.hideFromTablet;
}

.placeholder {
  @include theme.absoluteFill;
  @include theme.textM;
  @include theme.flexRow(center);

  text-align: left;
  padding: inherit;
  color: theme.$colorGrey600;
  white-space: nowrap;

  @include theme.mediaTabletPortraitUp {
    @include theme.textL;
  }
}
