@use 'styles' as theme;
.root {
  @include theme.square(100%);
  @include theme.flexCenter($direction: column);

  position: relative;
  border-radius: inherit;
  background-color: theme.$colorBlue;
}

.stars {
  @include theme.circle(theme.size(3));
}
