@use 'styles' as theme;

.root {
  @include theme.hideOnPrint;

  background: theme.$colorYellow;
}

.wrapper {
  position: relative;
  padding: theme.size(2);
}

.icon {
  @include theme.square(theme.size(4));
}

.closeButton {
  position: absolute;
  right: theme.size(-0.75);
}

.discount {
  display: inline;
}
