@use 'styles' as theme;
.vatQuestion {
  @include theme.outlineOnFocus;
  @include theme.transition(background-color color, $focusOutline: true);
  @include theme.flexCenter($inline: true);
  @include theme.circle(theme.size(2));

  background: rgba(theme.$colorRed, 0.2);
  margin-left: theme.size(0.5);
  color: inherit;

  &:hover {
    background: theme.$colorRed;
    color: theme.$colorWhite;
  }
}
