@use 'styles' as theme;
@import 'styles/variables.module';

.root {
  min-width: theme.size(30);
  width: fit-content;
  padding: theme.size(2 0);
  border: 0;
}

.anchor {
  text-wrap: nowrap;

  &:active {
    transform: scale(1);
    background-color: transparent;
  }
}

.button {
  &.open {
    background-color: theme.$colorBlack;
    color: theme.$colorWhite;
  }
}

.linkButton {
  @include theme.textMStrong;

  border-radius: theme.$borderRadiusXXS;
  display: flex;
  padding: theme.size(0 0.5);
  margin: theme.size(0 -0.5);
  align-items: center;
  gap: theme.size(0.5);
  background-color: theme.$colorWhite;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: $headerHeight;
    width: 100%;
  }

  &:not(:disabled):hover {
    background-color: theme.$colorWhite;
  }

  &.open {
    color: theme.$colorBlue;
  }
}

.trailingIcon {
  --rotate: 0;

  transform: rotate(var(--rotate));
  margin-left: 0;

  .open & {
    --rotate: 180deg;
  }
}

.leadingIcon {
  margin-right: theme.size(0.5);
}
