@use 'styles' as theme;

.root {
  @include theme.outlineOnFocus($offset: theme.size(0.5));

  --phoneNumberColor: inherit;

  border-radius: theme.$borderRadiusXXS;
  display: block;

  &:hover,
  &:focus {
    --phoneNumberColor: #{theme.$colorBlue};
  }
}

.phoneNumber {
  @include theme.transition(color);

  color: var(--phoneNumberColor);
}

.content {
  display: flex;
  flex-direction: row-reverse;
  gap: theme.size(2);

  @include theme.mediaTabletLandscapeUp {
    flex-direction: row;
  }
}
