@use '~@carvertical/ui/src/styles' as theme;
@import './colors.module';

$width: 1200px;
$minWidth: 320px;
$maxWidth: 2580px;
$expandedWidth: 1200px;

$tabletPortraitBreakpoint: 680px;
$tabletLandscapeBreakpoint: 990px;
$desktopBreakpoint: 1200px;
$bigDesktopBreakpoint: 1800px;

$containerSmallPadding: 15px;
$containerLargePadding: 40px;
$headerHeight: 64px;
$offCanvasHorizontalPadding: 12px;

$fontFallback: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font: 'Inter', $fontFallback;
$fontMonospaced: 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New', monospace;

$fontSize: 16px;

$weightRegular: 400;
$weightLight: $weightRegular;
$weightSemibold: 600;
$weightMedium: $weightSemibold;
$weightBold: 700;
$weightBlack: 900;

$borderRadiusXsm: 2px;
$borderRadiusSm: 5px;
$borderRadiusMd: 8px;
$borderRadius: 10px;
$borderRadiusLg: 16px;
$borderRadiusXlg: 32px;

$zIndexFloatingButton: 10;
$zIndexTooltip: 15;
$zIndexHeader: 20;
$zIndexBackdrop: 25;
$zIndexModal: 30;
$zIndexSupportButton: 40;

$sizeSectionPadding: (
  'large': (
    'desktop': theme.size(15),
    'mobile': theme.size(8),
  ),
  'small': (
    'desktop': theme.size(12),
    'mobile': theme.size(8),
  ),
);

:export {
  tabletPortraitBreakpoint: $tabletPortraitBreakpoint;
  tabletLandscapeBreakpoint: $tabletLandscapeBreakpoint;
  desktopBreakpoint: $desktopBreakpoint;
  bigDesktopBreakpoint: $bigDesktopBreakpoint;
  headerHeight: $headerHeight;
}
