@use 'styles' as theme;
.root {
  @include theme.flexCenter($inline: true);

  border: 0;
  margin: 0;
  background-color: theme.$colorWhite;
  border-radius: theme.$borderRadiusPill;
  padding: theme.size(0.25);
}

.light {
  border: 1px solid theme.$colorBlack;
}
