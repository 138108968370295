@use 'styles' as theme;

.root {
  --buttonHeight: #{theme.size(9)};
  padding-bottom: var(--buttonHeight);
}

.list {
  @include theme.unstyledList;

  padding: theme.size(1 1 0 1);
  margin: theme.size(0 -1);
  overflow: visible auto;
}

.content {
  padding: theme.size(2);
  background-color: theme.$colorGrey200;
  border-radius: 0 0 theme.$borderRadiusXS theme.$borderRadiusXS;
}

.listItemButton {
  @include theme.outlineOnFocus;
  @include theme.unstyledButton;
  @include theme.transition(background-color);
  @include theme.flexRow($crossAxisAlign: flex-start);

  padding: theme.size(2);
  border-radius: theme.$borderRadiusXS;
  background-color: theme.$colorGrey200;
  column-gap: theme.size(1);
  white-space: normal;

  &:active {
    transform: none;
  }

  &:not([disabled]) {
    &:hover {
      background-color: theme.$colorGrey400;
    }
  }
}

.opened {
  border-radius: theme.$borderRadiusXS theme.$borderRadiusXS 0 0;
}

.descriptionWrapper {
  padding-top: 0;
  padding-left: theme.size(5);
}

.iconWrapper {
  display: flex;
  align-self: stretch;
  padding-top: theme.size(0.5);
}

.buttonWrapper {
  background-color: theme.$colorWhite;
  bottom: 0;
  left: 0;
  position: absolute;
  height: var(--buttonHeight);
  padding: theme.size(2);
  width: 100%;
}
