@use 'styles' as theme;
@import 'styles/mixins';

.root {
  --width: #{theme.size(40)};
  --borderSize: 1px;
  --borderColor: #{theme.$colorGrey600};
  --textColorDark: #{theme.$colorGrey900};
  --textColorLight: #{theme.$colorGrey600};
  --bgColor: none;
  --cursor: pointer;
  --labelColor: #{theme.$colorGrey900};
  --focusBorderColor: #{theme.$colorBlue500};

  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: theme.size(1);
  width: min(var(--width), 100%);

  &:focus-within {
    --borderSize: 2px;
    --borderColor: var(--focusBorderColor);
  }
}

.light {
  --bgColor: #{theme.$colorWhite};
  --borderColor: #{theme.$colorGrey400};
}

.lightV2 {
  --bgColor: #{theme.$colorWhite};
  --labelColor: #{theme.$colorBlack};
  --borderColor: #{theme.$colorGrey600};
  --focusBorderColor: #{theme.$colorBlack};
}

.disabled {
  --borderColor: #{theme.$colorGrey400};
  --textColor: #{theme.$colorGrey400};
  --bgColor: #{theme.$colorGrey50};
  --placeholderColor: #{theme.$colorGrey400};
  --cursor: not-allowed;
}

.error {
  --borderColor: #{theme.$colorRed};
  --textColor: #{theme.$colorRed};
}

.fullWidth {
  --width: 100%;
}

.label {
  @include bodyS;

  color: var(--labelColor);

  .lightV2 & {
    @include theme.textS;
  }
}

.select {
  @include unstyledInput;
  @include bodyM;
  @include ellipsis;

  cursor: inherit;
  width: 100%;
  padding: theme.size(1.5 6 1.5 2);
  z-index: 1;
}

.inputWrapper {
  @include transition(border-color box-shadow background-color color, normal);
  display: flex;
  align-items: center;
  position: relative;
  border-radius: $borderRadiusMd;
  box-shadow: inset 0 0 0 var(--borderSize) var(--borderColor);
  box-sizing: border-box;
  background-color: var(--bgColor);
  cursor: var(--cursor);
  border: none;

  &::placeholder {
    color: var(--placeholderColor);
  }
}

.text-dark {
  color: var(--textColorDark);
}

.text-light {
  color: var(--textColorLight);
}

.message {
  color: theme.$colorRed;
  text-align: left;
}
