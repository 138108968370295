@use 'styles' as theme;
@import '../../styles/variables.module';
@import '../../styles/mixins';

.root {
  display: inline-block;
  vertical-align: top;

  svg {
    @include square(100%);
    display: block;
  }

  &:not(.original) {
    svg,
    use {
      fill: currentColor;
    }
  }
}

.round,
.roundSolid {
  position: relative;
  overflow: hidden;
  display: inline-block;
  color: theme.$colorBlue;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background: currentColor;
    opacity: 0.15;
  }
}

.roundSolid {
  &::before {
    opacity: 1;
  }

  svg {
    color: white;
  }
}

.roundWrapper {
  position: relative;
  display: block;
  padding-bottom: 100%;

  svg {
    @include square(54%);
    @include absoluteCenter;
  }
}
