@use 'styles' as theme;

.root {
  @include theme.mediaTabletPortraitUp {
    height: theme.size(82);
  }
}

.backButton {
  --offsetX: #{theme.size(3)};
  --offsetY: #{theme.size(3)};

  left: var(--offsetX);
  position: absolute;
  top: var(--offsetY);
  z-index: 1;
}

.content {
  overflow: hidden auto;
}

.footer {
  flex-direction: column;
  gap: theme.size(2);

  @include theme.mediaTabletPortraitUp {
    flex-direction: row-reverse;
  }
}

.illustration {
  margin-bottom: theme.size(2);
}
