@use 'styles' as theme;

.linksWrapper {
  flex-direction: column;
  margin-bottom: theme.size(4);

  @include theme.mediaTabletLandscapeUp {
    flex-direction: row-reverse;
  }
}

.linksWrapper {
  @include theme.mediaPhoneOnly {
    margin-bottom: theme.size(6);
  }
}

.links {
  @include theme.unstyledList;
  @include theme.flexCenter;

  flex-wrap: wrap;
  gap: theme.size(3);
}

.link {
  @include theme.outlineOnFocus($offset: theme.size(1));
  @include theme.transition(color);

  &:hover {
    color: theme.$colorGrey600;
  }
}
