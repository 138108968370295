@use 'styles' as theme;

.root {
  margin-bottom: theme.size(6);

  @include theme.mediaDesktopUp {
    margin-bottom: 0;
  }
}

.blockExpanded {
  @include theme.mediaPhoneOnly {
    border-radius: theme.$borderRadiusM;
    background-color: theme.$colorGrey900;
    padding-bottom: theme.size(4);
  }
}

.subtitleButton {
  @include theme.unstyledButton;
  @include theme.transition(background-color);
  @include theme.outlineOnFocus;

  width: 100%;
  pointer-events: none;

  @include theme.mediaPhoneOnly {
    @include theme.flexRow($mainAxisAlign: space-between, $crossAxisAlign: center);

    padding: theme.size(2);
    background-color: theme.$colorGrey900;
    border-radius: theme.$borderRadiusM;
    pointer-events: all;
  }
}

.subtitle {
  @include theme.transition(color);
  @include theme.mediaTabletPortraitUp {
    color: theme.$colorGrey600;
    margin-bottom: theme.size(2);
  }
}

.links {
  @include theme.unstyledList;

  display: flex;
  flex-direction: column;

  @include theme.mediaPhoneOnly {
    display: none;

    .blockExpanded & {
      display: flex;
      padding-left: theme.size(4);
    }
  }
}

.link {
  @include theme.mediaDesktopUp {
    width: theme.size(22);
  }
}
