@use 'sass:list';
@use 'sass:string';

@mixin flex(
  $direction: column,
  $wrap: false,
  $crossAxisAlign: null,
  $mainAxisAlign: null,
  $inline: false
) {
  @if $wrap {
    flex-wrap: wrap;
  }

  @if $inline {
    display: inline-flex;
  } @else {
    display: flex;
  }

  flex-direction: $direction;
  align-items: $crossAxisAlign;
  justify-content: $mainAxisAlign;
}

@mixin flexColumn($crossAxisAlign: null, $mainAxisAlign: null, $inline: false) {
  @include flex(
    $crossAxisAlign: $crossAxisAlign,
    $mainAxisAlign: $mainAxisAlign,
    $direction: column,
    $inline: $inline
  );
}

@mixin flexRow($crossAxisAlign: null, $mainAxisAlign: null, $wrap: false, $inline: false) {
  @include flex(
    $crossAxisAlign: $crossAxisAlign,
    $mainAxisAlign: $mainAxisAlign,
    $direction: row,
    $wrap: $wrap,
    $inline: $inline
  );
}

@mixin flexCenter($inline: false, $direction: row) {
  @include flex(
    $crossAxisAlign: center,
    $mainAxisAlign: center,
    $inline: $inline,
    $direction: $direction
  );
}

@mixin absoluteFill($offset: 0) {
  position: absolute;
  inset: $offset;
}

@mixin absoluteCenter($type: 'xy', $size: 50%) {
  $x: if(string.index($type, 'x'), $size, null);
  $y: if(string.index($type, 'y'), $size, null);

  position: absolute;
  top: $y;
  left: $x;
  transform: translate(if($x, -#{$x}, 0), if($y, -#{$y}, 0));
}

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin circle($size) {
  @include square($size);

  border-radius: 50%;
}

@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  $_firstSize: list.nth($size, 1);

  $_oppositeBorder: if(list.length($size) == 2, list.nth($size, 2), $_firstSize) solid $color;
  $_perpendicularBorder: $_firstSize solid transparent;

  @if $direction == 'left' {
    border-top: $_perpendicularBorder;
    border-right: $_oppositeBorder;
    border-bottom: $_perpendicularBorder;
    border-left: none;
  }

  @if $direction == 'right' {
    border-top: $_perpendicularBorder;
    border-right: none;
    border-bottom: $_perpendicularBorder;
    border-left: $_oppositeBorder;
  }

  @if $direction == 'up' {
    border-top: none;
    border-right: $_perpendicularBorder;
    border-bottom: $_oppositeBorder;
    border-left: $_perpendicularBorder;
  }

  @if $direction == 'down' {
    border-top: $_oppositeBorder;
    border-right: $_perpendicularBorder;
    border-bottom: none;
    border-left: $_perpendicularBorder;
  }

  width: 0;
  height: 0;
}
