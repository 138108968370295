@use 'styles' as theme;

.root {
  background-color: theme.$colorWhite;
  padding: theme.size(2 0);
  position: relative;
  overflow: hidden;
}

.closeButton {
  flex-shrink: 0;

  @include theme.mediaPhoneOnly {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, 50%);
  }
}
