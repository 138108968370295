@use 'sass:math';
@use 'sass:meta';
@use '../variables' as var;
@use '../utils' as util;
@use './responsive';

@mixin _typography($size, $lineHeight, $weight: null) {
  @if $weight {
    font-weight: $weight;
  }

  font-family: var.$fontFamilyPrimary;
  font-size: util.rem($size);
  line-height: if(meta.type-of($lineHeight) == number, math.div($lineHeight, $size), $lineHeight);
}

@mixin headingXXL {
  @include _typography(40, 48, var.$weightBold);

  @include responsive.mediaTabletLandscapeUp {
    @include _typography(56, 72);
  }
}

@mixin headingXL {
  @include _typography(40, 48, var.$weightBold);

  @include responsive.mediaTabletLandscapeUp {
    @include _typography(48, 56);
  }
}

@mixin headingL {
  @include _typography(32, 40, var.$weightBold);

  @include responsive.mediaTabletLandscapeUp {
    @include _typography(40, 48);
  }
}

@mixin headingM {
  @include _typography(24, 32, var.$weightBold);

  @include responsive.mediaTabletLandscapeUp {
    @include _typography(32, 40);
  }
}

@mixin headingS {
  @include _typography(20, 24, var.$weightBold);

  @include responsive.mediaTabletLandscapeUp {
    @include _typography(24, 32);
  }
}

@mixin headingXS {
  @include _typography(18, 20, var.$weightSemiBold);

  @include responsive.mediaTabletLandscapeUp {
    @include _typography(20, 24);
  }
}

@mixin textL {
  @include _typography(18, 28);
}

@mixin textLStrong {
  @include _typography(18, 28, var.$weightSemiBold);
}

@mixin textM {
  @include _typography(16, 24, var.$weightRegular);
}

@mixin textMStrong {
  @include _typography(16, 24, var.$weightSemiBold);
}

@mixin textS {
  @include _typography(14, 20, var.$weightRegular);
}

@mixin textSStrong {
  @include _typography(14, 20, var.$weightSemiBold);
}

@mixin textXS {
  @include _typography(12, 16, var.$weightRegular);
}

@mixin textXSStrong {
  @include _typography(12, 16, var.$weightSemiBold);
}

@mixin textXXS {
  @include _typography(11, 16, var.$weightRegular);
}

@mixin textXXSStrong {
  @include _typography(11, 16, var.$weightSemiBold);
}

@mixin textXXXS {
  @include _typography(10, 14, var.$weightRegular);
}

@mixin textXXXSStrong {
  @include _typography(10, 14, var.$weightSemiBold);
}

@mixin buttonL {
  @include _typography(16, 24, var.$weightSemiBold);
}

@mixin buttonM {
  @include _typography(14, 24, var.$weightSemiBold);
}

@mixin buttonS {
  @include _typography(12, 16, var.$weightSemiBold);
}

@mixin ellipsis($linesToShow: 1) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $linesToShow;
}
