@use 'styles' as theme;
.content {
  max-width: theme.size(80);
  margin: 0 auto;
}

.link {
  @include theme.outlineOnFocus;
  @include theme.transition(color outline-offset outline-color);

  &:hover {
    color: theme.$colorBlue;
  }
}
