@use 'styles' as theme;

.root {
  display: flex;
  width: 100%;
}

.content {
  width: 100%;
}
