@use '../utils' as util;
@use './breakpoints' as breakpoints;

$sizeBaseline: util.size(1);

// Paddings
$framePaddingMobileUp: util.size(2);
$framePaddingTabletLandscapeUp: util.size(4);
$framePaddingDesktopUp: util.size(12.5);

// Frame width
$sizeMinWidth: 360px;
$sizeFrameWidth: 1408px;

// Gutters
$gutterMobile: util.size(2);
$gutterTabletPortrait: util.size(3);
$gutterTabletLandscapeUp: util.size(4);

// Border radius
$borderRadiusXXS: util.size(0.5);
$borderRadiusXS: util.size(1);
$borderRadiusS: util.size(1.25);
$borderRadiusM: util.size(1.5);
$borderRadiusL: util.size(2);
$borderRadiusXL: util.size(3);
$borderRadiusPill: 100vmax;
