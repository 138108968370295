@use 'styles' as theme;
.root {
  text-align: center;
}

.black {
  color: theme.$colorBlack;
}

.white {
  color: theme.$colorWhite;
}

.inverted {
  opacity: 0.75;
  color: theme.$colorWhite;
}

.leftAligned {
  text-align: left;
}

.logos {
  @include theme.unstyledList;
  @include theme.flexCenter;

  flex-wrap: wrap;
  gap: theme.size(2.5 5);
  margin-top: theme.size(2.5);

  @include theme.mediaPhoneOnly {
    li:last-child {
      display: none;
    }
  }

  .leftAligned & {
    justify-content: flex-start;
  }
}

.logoImage {
  opacity: 0.265;
  color: theme.$colorBlack;

  .inverted & {
    opacity: 1;
    filter: invert(1);
  }

  .white & {
    color: theme.$colorWhite;
    opacity: 1;
    filter: brightness(0) invert(1);
  }
}
