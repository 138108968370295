@use 'styles' as theme;

.root {
  background-color: theme.$colorGrey900;
  border-radius: theme.$borderRadiusXL;
  padding: theme.size(3);
  margin-bottom: theme.size(6);

  @include theme.mediaTabletLandscapeUp {
    margin-bottom: 0;
  }

  @include theme.mediaDesktopUp {
    min-width: theme.size(74);
  }
}

.button {
  @include theme.flexCenter;
  @include theme.outlineOnFocus;
  @include theme.transition(opacity);

  position: relative;
  border-radius: theme.$borderRadiusPill;
  border: 1px solid theme.$colorWhite;
  padding: theme.size(1.5 3);

  &:hover {
    opacity: 0.5;
  }
}

.icon {
  @include theme.square(theme.size(3));

  position: absolute;
  top: 50%;
  left: theme.size(3);
  transform: translateY(-50%);
  color: theme.$colorWhite;
}

.shiftUp {
  transform: translateY(calc(-50% - 1px));
}

.label {
  margin-left: theme.size(4);
}
