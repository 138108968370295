@use 'styles' as theme;

.header {
  @include theme.flex($direction: row, $crossAxisAlign: center, $mainAxisAlign: space-between);

  padding: theme.size(0.5 0 0);
  position: relative;
}

.backButton {
  @include theme.unstyledButton;
  @include theme.textSStrong;
  @include theme.outlineOnFocus($selector: '&[data-focus-visible]');

  -webkit-tap-highlight-color: transparent;
  padding: theme.size(1 0);
  margin: theme.size(-1 0 -1 -1.25);
  color: theme.$colorBlue;
  text-decoration: none;
}

.title {
  position: absolute;
  inset: auto 0;
  text-align: center;
  pointer-events: none;
}

.submenuTitle {
  padding: theme.size(2 0 1.25);
}

.divider {
  --offsetX: -#{theme.$framePaddingMobileUp};

  height: 1px;
  background-color: theme.$colorGrey200;
  align-self: stretch;
  margin: theme.size(1.5) var(--offsetX);
  display: flex;

  @include theme.mediaTabletLandscapeUp {
    --offsetX: -#{theme.$framePaddingTabletLandscapeUp};
  }
}

.footer {
  padding: theme.size(1 0);
}
