@use 'styles' as theme;

.root {
  min-width: theme.size(30);
  width: fit-content;
  padding: theme.size(3 1);
  border: 0;
}

.s {
  @include theme.textS;
}

.m {
  @include theme.textM;
}

.header {
  padding: theme.size(0 2 1);
}

.footer {
  padding: theme.size(1 2);
}

.itemWrapper {
  width: 100%;
  position: relative;
}

.item {
  @include theme.transition(background-color);
  @include theme.outlineOnFocus();

  background-color: transparent;
  padding: theme.size(1.5 2);
  display: flex;
  width: 100%;
  border-radius: theme.$borderRadiusS;

  &:hover {
    background-color: theme.$colorGrey50;
  }
}

.active {
  pointer-events: none;
  color: theme.$colorBlue;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.divider {
  height: 1px;
  background-color: theme.$colorGrey200;
  margin: theme.size(2 -2);

  --offsetX: -#{theme.$framePaddingMobileUp};

  @include theme.mediaTabletLandscapeUp {
    --offsetX: -#{theme.$framePaddingTabletLandscapeUp};
  }

  &:last-child,
  &:first-child {
    display: none;
  }
}

.separate {
  --offsetTop: #{calc(theme.size(4) + 1px)};

  margin-top: var(--offsetTop);

  &::before {
    @extend .divider;

    position: absolute;
    content: '';
    inset: calc(var(--offsetTop) * -1) 0 0;
  }
}
