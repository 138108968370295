@use 'styles' as theme;

.root {
  @include theme.unstyledButton;
  @include theme.outlineOnFocus($selector: '&[data-focus-visible]', $offset: theme.size(-0.25));
  @include theme.transition(background-color, $focusOutline: true);
  @include theme.circle(theme.size(6));
  @include theme.flex($crossAxisAlign: center, $mainAxisAlign: center);

  margin: theme.size(0 -1.25);
  border-radius: theme.$borderRadiusPill;
  -webkit-tap-highlight-color: transparent;

  &[data-hovered],
  &[data-pressed] {
    background-color: theme.$colorGrey50;
  }

  @include theme.mediaDesktopUp {
    @include theme.visuallyHidden;
  }
}
