@use 'styles' as theme;

.innerLink {
  @include theme.outlineOnFocus($offset: theme.size(1));
  @include theme.transition(color);

  font-size: inherit;
  color: theme.$colorWhite;
  border-radius: theme.$borderRadiusM;

  &:hover {
    color: theme.$colorGrey600;
  }
}
