@use '../variables' as var;
@use '../utils' as util;
@use './layout';
@use './transitions';

@mixin visuallyHidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
}

@mixin extendedClickableArea($offset) {
  position: relative;

  &::before {
    @include layout.absoluteFill(calc($offset * -1));

    content: '';
  }
}

@mixin mouseOnlyHover {
  &:hover {
    @media (hover: hover) and (pointer: fine) {
      @content;
    }
  }
}

@mixin outlineOnFocus(
  $width: 2px,
  $color: var.$colorBlue300,
  $offset: util.size(0.25),
  $selector: '&:focus-visible'
) {
  @include transitions.transition((), $focusOutline: true);

  outline: $width solid transparent;

  #{$selector} {
    outline-color: $color;
    outline-offset: $offset;
  }
}
