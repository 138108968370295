@use 'styles' as theme;

.misspelledEmailButton {
  @include theme.unstyledButton;
  @include theme.outlineOnFocus($offset: theme.size(1));
  @include theme.transition(color);

  color: theme.$colorWhite;
  font-weight: theme.$weightBold;

  &:hover {
    color: theme.$colorBlue;
  }
}
