@use 'styles' as theme;

.title {
  @include theme.mediaTabletPortraitUp {
    margin-bottom: theme.size(2);
  }
}

.twoColumns {
  @include theme.mediaDesktopUp {
    width: 50%;
  }
}
