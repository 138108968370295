@use 'styles' as theme;
.root {
  display: flex;
  position: relative;
}

.button {
  @include theme.outlineOnFocus;
  @include theme.transition(
    color background-color transform,
    $focusOutline: true
  );

  z-index: 1;
  padding: theme.size(0.25 2);
  border-radius: theme.$borderRadiusPill;
  text-transform: uppercase;
  cursor: pointer;

  &:not(.active):hover {
    background-color: theme.$colorGrey200;
  }
}

.focused {
  outline-color: theme.$colorBlue300;
}

.disabled {
  cursor: not-allowed;
  background-color: theme.$colorGrey50;
}

.activeIndicator {
  @include theme.absoluteFill;
  @include theme.transition(background-color color);

  border-radius: theme.$borderRadiusPill;
  background-color: theme.$colorBlue;
}
