@use 'styles' as theme;
@use 'sass:map';
@import '../../../styles/variables.module';
@import '../../../styles/mixins';

.root {
  background-color: var(--backgroundColor);
  overflow: hidden;
}

.l {
  @include sectionVerticalPadding($sizeVariant: 'large');
}

.s {
  @include sectionVerticalPadding($sizeVariant: 'small');
}
