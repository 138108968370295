$colorBlue50: #eff9ff;
$colorBlue100: #d7f0ff;
$colorBlue200: #c4e7ff;
$colorBlue300: #aadcff;
$colorBlue400: #7dbefb;
$colorBlue500: #4d9df5;
$colorBlue: #0073f0;
$colorBlue700: #163fd3;
$colorBlue800: #0024a3;
$colorBlue900: #000f78;

$colorYellow50: #fffdea;
$colorYellow100: #fffbd4;
$colorYellow200: #fff7a9;
$colorYellow300: #fff169;
$colorYellow: #ffeb28;
$colorYellow500: #ffd600;
$colorYellow600: #f2ae00;
$colorYellow700: #d28d08;
$colorYellow800: #aa7000;
$colorYellow900: #835004;

$colorGrey50: #f9f9f9;
$colorGrey100: #f4f4f4;
$colorGrey200: #f0f0f0;
$colorGrey300: #e6e6e6;
$colorGrey400: #d6d6d6;
$colorGrey500: #bababa;
$colorGrey600: #858585;
$colorGrey700: #4d4d4d;
$colorGrey800: #373737;
$colorGrey900: #1f1f1f;

$colorRed50: #fff5f5;
$colorRed100: #ffebeb;
$colorRed200: #ffdbdb;
$colorRed300: #ffc8c8;
$colorRed400: #ff9898;
$colorRed: #ff5f5f;
$colorRed600: #dc2121;
$colorRed700: #b00202;
$colorRed800: #7e0000;
$colorRed900: #510100;

$colorGreen50: #e4fbe7;
$colorGreen100: #d2fad7;
$colorGreen200: #b2f2bb;
$colorGreen300: #8de398;
$colorGreen400: #5ed17f;
$colorGreen: #00c364;
$colorGreen600: #00a556;
$colorGreen700: #038b4a;
$colorGreen800: #005d31;
$colorGreen900: #02341c;

$colorBlack: #000;
$colorWhite: #fff;
