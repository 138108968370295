@use 'sass:list';
@use '../variables' as var;
@use '../utils' as util;

@mixin transition($properties, $params: var.$transitionFast, $focusOutline: false) {
  $result: ();

  @if $focusOutline {
    $properties: list.join($properties, list.zip(var.$focusOutlineTransitionProps));
  }

  @each $property in $properties {
    $result: list.append($result, ($property $params), $separator: comma);
  }

  // stylelint-disable-next-line order/order
  transition: $result;
}
