@use 'styles' as theme;
@import '../../styles/variables.module';
@import '../../styles/mixins';

.root {
  position: relative;
  aspect-ratio: 24 / 18;
  width: var(--size);

  &.rounded {
    overflow: hidden;
    border-radius: 50%;
    aspect-ratio: 1;

    .flag {
      border-radius: 50%;
    }
  }
}

.flag {
  object-fit: cover;
}
