@use 'styles' as theme;

$_sizes: (
  's': 3,
  'm': 4,
  'l': 6,
);

.root {
  @include theme.hideUntilTablet;

  background-color: theme.$colorGrey900;
  height: 1px;
}

@each $sizeKey, $sizeValue in $_sizes {
  .#{$sizeKey} {
    margin: theme.size($sizeValue 0);
  }
}
