@use 'styles' as theme;

.link {
  text-wrap: nowrap;
}

.loginLink {
  background-color: transparent;
  width: fit-content;

  // TODO: remove when `transparent` variant is supported by `@carvertical/ui`
  &:not(:disabled):hover {
    background-color: transparent;
    color: theme.$colorBlue;
  }
}
