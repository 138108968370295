@use 'styles' as theme;
@import 'styles/variables.module';

.root {
  display: flex;
  background-color: theme.$colorWhite;
  box-shadow: 0 1px 0 0 theme.$colorGrey200;
  height: $headerHeight;
}

.container {
  @include theme.flex($direction: row, $crossAxisAlign: center, $mainAxisAlign: space-between);

  flex: 1;
  gap: theme.size(3);
}

.logo {
  transform: translateY(-1px);
}

.contactButton {
  text-align: center;
}

.impersonationBanner {
  width: 100%;
  display: block;
  border-radius: 0;
  text-align: center;
}
