@use 'styles' as theme;
.wrapper {
  text-align: center;
  margin: 0 auto;
  max-width: theme.size(100);
}

.avatars {
  margin: 0;
}

.avatar {
  border-radius: 50%;
  border: 2px solid theme.$colorWhite;

  &:not(:last-child) {
    margin-right: theme.size(-1.5);
  }
}

.icon {
  @include theme.square(theme.size(10));
  color: transparent;

  @include theme.mediaTabletLandscapeUp {
    @include theme.square(theme.size(15));
  }
}

.feature {
  @include theme.flexCenter($direction: column);
  flex: 1;
  text-align: center;
  min-width: theme.size(15);

  @include theme.mediaTabletLandscapeUp {
    min-width: theme.size(24);
  }
}
