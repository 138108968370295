@use '~@carvertical/ui/src/styles' as theme;

$colorGridBackground: rgba(theme.$colorBlack, 0.03);
$colorGridLines: rgba(theme.$colorBlack, 0.05);
$colorGridLinesLight: rgba(theme.$colorBlack, 0.03);
$colorGridLinesDark: rgba(theme.$colorBlack, 0.07);

$colorYoutube: #f00;

:export {
  gridBackground: $colorGridBackground;
  gridLines: $colorGridLines;
  gridLinesLight: $colorGridLinesLight;
  gridLinesLightDark: $colorGridLinesDark;
}
