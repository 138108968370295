@use 'styles' as theme;
@import '../../styles/variables.module';
@import '../../styles/mixins';

.root {
  @include flexCentered;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  padding: 40px 20px;
  background: white;
  text-align: center;

  > p {
    max-width: 500px;
    margin: 0.5em 0;
  }
}

.error {
  color: theme.$colorRed;
}
